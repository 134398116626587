import React from 'react'
import { Paper, makeStyles, Typography } from '@material-ui/core'
import CountUp from "react-countup";
import AppearSensor from './appear-visibility'

const useStyles = makeStyles(theme => ({
    root: {
        display: 'flex',
        flexDirection: 'column',
        minHeight: '300px',
        height: '100%',
        justifyContent: 'center',
        [theme.breakpoints.down('sm')]: {
            minHeight: '0px'
        },
        backgroundColor:theme.palette.primary.main,
        flex: '1 1 30%',

    },
    counter: {
        padding: '10%',
        textAlign: 'center',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        height: '100%'

    },
    number: {
        fontFamily: ['Helvetica Neue', 'sans-serif'],
        fontWeight: 600,
        fontSize: '4rem',
        [theme.breakpoints.down('sm')]: {
            fontSize: '2.5rem'
        },
        lineHeight: '1;',
        marginBottom: '5px;',
        textTransform: 'uppercase;',

    },
    label: {
        fontFamily: ['Helvetica Neue', 'sans-serif'],
        fontWeight: 600,
        fontSize: '1.5rem',
        [theme.breakpoints.down('sm')]: {
            fontSize: '1rem'
        },
        color: theme.palette.text.secondary,
    }

}))

const Counter = (props) => {
    const classes = useStyles()
    return (

        <Paper square elevation={0} className={classes.root}>
            <div className={classes.counter}>
                <AppearSensor>
                    {({ hasBeenVisible }) =>
                        hasBeenVisible
                            ? <CountUp start={0} separator='.' duration={1} end={props.number} className={classes.number} />
                            : <Typography className={classes.number} >{props.number}+</Typography>
                    }
                </AppearSensor>
               
            

                <Typography component='span' className={classes.label}>{props.label}</Typography>
            </div>

        </Paper>
    )

}

export default Counter