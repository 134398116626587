import React from 'react'
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import { Box, Typography, Button, makeStyles } from '@material-ui/core';
import Img from 'gatsby-image'
import { useStaticQuery, Link, graphql } from 'gatsby'


const useStyles = makeStyles(theme => ({
    container: {
        marginTop: theme.spacing(1),

    },
    card: {
        position: 'relative',
        width: '100%',
        minHeight: '300px',
        height: 'auto',
    },
    mediaContainer: {
        width: '100%',

    },
    content: {
        position: 'absolute',
        top: 0,
        right: 0,
        paddingTop: '15%',
        paddingBottom: 'auto',
        paddingRight: '5%',
        width: '50%',
        zIndex: 1,
        [theme.breakpoints.down('sm')]: {
            position: 'relative',
            width: '100%',
            paddingTop: '2%',


        },
        '&.left': {
            top: 0,
            left: 0,
            paddingTop: '15%',
            paddingLeft: '5%',
            [theme.breakpoints.down('sm')]: {
                position: 'relative',
                width: '100%',
                paddingTop: '2%',
                paddingLeft: 0,
            }
        }
    },
    left: {},
    controls: {
        display: 'flex',
        width: '100%',
        alignItems: 'center',
        paddingTop: theme.spacing(1),
        paddingBottom: theme.spacing(1),
        gap: '3%'
    },
    button: {
        whiteSpace: 'nowrap',
        color: 'white',
        backgroundColor: 'black',
        borderRadius: 0,
        "&:hover, &:focus": {
            color: 'black',
            backgroundColor: 'white',
        }
    },
    light: {
        color: 'black!important',
        backgroundColor: 'white!important',
        borderRadius: 0,
        "&:hover, &:focus": {
            color: 'white!important',
            backgroundColor: 'black!important',
        },
        [theme.breakpoints.down('sm')]: {
            color: 'white!important',
            backgroundColor: 'black!important',
            borderRadius: 0,
            "&:hover, &:focus": {
                color: 'black!important',
                backgroundColor: 'white!important',
            }
        }
    },
    lightText: {
        color: 'white',
        fontSize:'1.3rem',
        [theme.breakpoints.down('sm')]: {
            color: 'black',
            fontSize:'1rem',
        }
    },
    contentWrapper: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center'
    },
    header: {
        fontFamily: ['Helvetica Neue', 'sans-serif'],
        fontWeight: 600,
        fontSize: '2rem',
        [theme.breakpoints.down('sm')]: {
            fontSize: '1.5rem'
        }
    },
    subtitle:{
        fontSize:'1.3rem',
        [theme.breakpoints.down('sm')]:{
            fontSize:'1rem',
        }
    }
}))

const FiCard = (props) => {
    const classes = useStyles()
    const data = useStaticQuery(
        graphql`
      query {
        photo: allFile(filter: {sourceInstanceName: {eq:"images"}}) {
        nodes {
            relativePath
            childImageSharp {
            fluid(quality: 90, maxWidth: 600) {
                ...GatsbyImageSharpFluid_withWebp
                aspectRatio
            }
          }

        }
        
        }
      }
    `)
    /* eslint eqeqeq: 0 */
    const heroImg = data.photo.nodes.find(el => el.relativePath == props.img)
    let position = props.left ? 'left' : ''
    let background = props.dark ? 'dark' : ''
    const controls = props.controls
    return (
        <Box width={1} className={classes.container}>
            <Card className={classes.card} square elevation={0}>
                <div>
                    <Img fluid={heroImg.childImageSharp.fluid} />
                </div>
                <div className={`${classes.content} ${position}`}>
                    <CardContent className={classes.contentWrapper}>
                        <Typography component="h5" variant="h5" className={background ? `${classes.header} ${classes.lightText}` : classes.header} align='center'>
                            {props.title}
                        </Typography>
                        <Typography variant="subtitle1" className={background ? classes.lightText : classes.subtitle} align='center' paragraph>
                            {props.subtitle}
                        </Typography>
                        {(controls.length > 0) && <div className={classes.controls}>
                            {controls.map((control, index) =>
                                <Button key={index} fullWidth variant="contained" className={background ? `${classes.button} ${classes.light}` : classes.button} component={Link} to={control.link}>{control.label}</Button>
                            )}
                        </div>}
                    </CardContent>
                </div>
            </Card>
        </Box>
    )


}
export default FiCard